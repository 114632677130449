export default ({ data }) => {
  return {
    methods: data.methods.map((method) => ({
      id: method.id,
      number: method.num,
      title: method.title,
      text: method.text,
      links: method.links?.map(({ id: linkId, link }) => ({
        id: linkId,
        url: link.link,
        target: link.target,
        text: link.text,
        icon: link.icon,
      })),
    })),
  };
};
